import React, { useState, useCallback, FunctionComponent } from 'react';
import styled from 'styled-components';
import { BillingDetails } from './BillingDetails';
import { CheckoutForm } from './CheckoutForm';
import { useCheckout } from '@bunac/hooks/useCheckout';
import createPersistedState from 'use-persisted-state';
import { Grid, ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import {
  Currencies,
  currency as getCurrency,
  DEFAULT_CURRENCY,
} from '@bunac/components/config/currency';
import { PaymentChoices } from './PaymentChoice';

const useCurrency = createPersistedState('currency');

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0094A5',
    },
    secondary: {
      main: '#0094A5',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: '#0094A5',

        '&:hover': {
          borderColor: '#0094A5',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        borderColor: '#0094A5',
      },
      notchedOutline: {
        borderColor: '#0094A5',
      },
    },
    MuiSelect: {
      root: {
        fontFamily: 'proxima-nova, sans-serif',
        fontSize: 14,
        color: '#0094A5',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'proxima-nova, sans-serif',
        fontSize: 14,
        color: '#0094A5',
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: 'proxima-nova, sans-serif',
        fontWeight: 300,
        color: '#212121',
      },
    },
    MuiTextField: {
      root: {
        marginBottom: '12px',
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: '0',
      },
    },
  },
});

interface PaymentFormProps {
  onNext: () => void;
}

const Container = styled.div`
  margin-bottom: 20px;
`;

const PaymentForm: FunctionComponent<PaymentFormProps> = ({ onNext }) => {
  const { programme, level, setPaymentType } = useCheckout();

  const [currency] = useCurrency(Currencies.default);

  const [billingDetailsSaved, setBillingDetailsSaved] =
    useState<boolean>(false);

  const onBillingDetailSaved = useCallback(() => {
    setBillingDetailsSaved(true);
  }, [setBillingDetailsSaved]);

  const onPaymentDetailsSaved = useCallback(
    (choice: PaymentChoices) => {
      if (setPaymentType === undefined) {
        return;
      }
      setPaymentType(choice);
      onNext();
    },
    [onNext, setPaymentType]
  );

  if (!programme || !level) {
    return <></>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Grid container={true}>
          <Grid xs={12} md={7} item={true}>
            <BillingDetails
              disabled={billingDetailsSaved}
              currency={currency}
              onNext={() => onBillingDetailSaved()}
            />
          </Grid>
          <Grid xs={12} md={5} item={true}>
            <CheckoutForm
              programme={programme}
              level={level}
              currency={getCurrency(currency || DEFAULT_CURRENCY.code)}
              active={billingDetailsSaved}
              onNext={onPaymentDetailsSaved}
            />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export { PaymentForm };
