import React, { FunctionComponent } from 'react';
import { useCheckout } from '@bunac/hooks/useCheckout';
import styled from 'styled-components';

const Title = styled.h1`
  font-weight: 500;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    margin-bottom: 60px;
  }
`;

const ApplicationHeader: FunctionComponent<{}> = () => {
  const { programme } = useCheckout();

  return (
    <div className="application-header">
      <Title>Application for {programme?.Title}</Title>
    </div>
  );
};

export { ApplicationHeader };
