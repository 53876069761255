import React, {
  useState,
  useCallback,
  useEffect,
  FunctionComponent,
} from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { IProduct } from '@bunac/lib/model/Programme';
import { ProgrammeLevelItem } from './ProgrammeLevelItem';
import createPersistedState from 'use-persisted-state';
import {
  currency as getCurrency,
  DEFAULT_CURRENCY,
} from '../../config/currency';
import { SubmitButton } from '../Form/SubmitButton';
import { FormTitle } from './FormTitle';
import { useCheckout } from '@bunac/hooks/useCheckout';
import { Loading, LoaderColour } from '@bunac/components/common/Loading';

const useCurrency = createPersistedState('currency');

interface ProgrammeLevelProps {
  onNext: () => void;
}

const Container = styled.div`
  margin-bottom: 20px;
`;

const ItemContainer = styled(Grid)`
  align-items: stretch;
`;

const ProgrammeLevel: FunctionComponent<ProgrammeLevelProps> = ({ onNext }) => {
  const { programme, setLevel, isAssignedLevel, isLoading } = useCheckout();

  const [currency] = useCurrency();

  const [selectedLevel, setSelectedLevel] = useState<IProduct>();

  useEffect(() => {
    if (isAssignedLevel) {
      onNext();
    }
  }, [isAssignedLevel, onNext]);

  const onSubmit = useCallback(() => {
    if (selectedLevel === undefined || setLevel === undefined) {
      return;
    }
    setLevel(selectedLevel);
  }, [setLevel, selectedLevel]);

  const onLevelSelected = useCallback(
    (selected: IProduct) => {
      setSelectedLevel(selected);
    },
    [setSelectedLevel]
  );

  if (!programme) {
    return <></>;
  }
  return (
    <Container>
      <FormTitle>Choose your work abroad experience</FormTitle>
      {currency !== undefined && (
        <ItemContainer container={true}>
          {programme.Products.filter((p) => p.Type === 'Level').map((level) => (
            <Grid key={level.Identifier} item={true} xs={12} md={4}>
              <ProgrammeLevelItem
                currency={getCurrency(currency || DEFAULT_CURRENCY.code)}
                programme={programme}
                product={level}
                selected={selectedLevel?.Identifier === level.Identifier}
                onSelect={onLevelSelected}
              />
            </Grid>
          ))}
        </ItemContainer>
      )}
      <SubmitButton
        disabled={selectedLevel === undefined}
        onClick={() => onSubmit()}
      >
        {!isLoading && 'Submit & Continue'}
        {isLoading && <Loading size={25} color={LoaderColour.white} />}
      </SubmitButton>
    </Container>
  );
};

export { ProgrammeLevel };
