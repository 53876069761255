import React, {
  useState,
  useCallback,
  useEffect,
  FunctionComponent,
} from 'react';
import formatSlug from '@bunac/lib/slugFormatter';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SubmitButton } from '@bunac/components/forms/Form/SubmitButton';
import { FormTitle } from './FormTitle';
import styled from 'styled-components';
import { siteMetadata } from '@bunac/../gatsby-config';
import { IProduct, IProgramme } from '@bunac/lib/model/Programme';
import { PaymentChoices } from './PaymentChoice';
import { ICurrency } from '@bunac/components/config/currency';
import WarningIcon from '@material-ui/icons/Warning';
import { LoaderColour, Loading } from '@bunac/components/common/Loading';
import { useCheckout } from '@bunac/hooks/useCheckout';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const Container = styled.div`
  margin-top: 59px;

  &.disabled {
    opacity: 0.5;
  }
`;

const CardError = styled.div`
  background: #fa755a;
  padding: 12px 12px 8px;
  margin: 12px 0;
  color: #fff;
`;

const CardErrorMessage = styled.span`
  display: inline-block;
  position: relative;
  top: -4px;
  margin-left: 8px;
`;

const cardStyle = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#0094A5',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#0094A5',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

interface StripePaymentProps {
  billingDetailSaved: boolean;
  programme: IProgramme;
  level: IProduct;
  choice: PaymentChoices;
  currency: ICurrency;
  amount: number;
  onPaymentSuccess: () => void;
}

const StripePayment: FunctionComponent<StripePaymentProps> = ({
  programme,
  amount,
  level,
  choice,
  currency,
  billingDetailSaved,
  onPaymentSuccess,
}) => {
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const { confirmPayment, email } = useCheckout();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    window
      .fetch(siteMetadata.Stripe.PAYMENT_INTENT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          programme: programme.Pipedrive_ID,
          level: level.Identifier,
          choice,
          currency: currency.code.toLowerCase(),
        }),
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.secret);
      });
  }, [programme, level, choice, currency]);

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const onTermsAndConditionsChanged = async (event: any) => {
    setAcceptedTerms(event.target.checked);
  };

  const handleSubmit = useCallback(
    async (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();

      if (
        stripe === null ||
        elements === null ||
        confirmPayment === undefined
      ) {
        return;
      }
      setProcessing(true);
      const el = elements.getElement(CardElement);

      if (el === null) {
        setProcessing(false);
        return;
      }
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: el,
          billing_details: {
            email,
          },
        },
      });

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        await confirmPayment({
          id: payload.paymentIntent.id,
          paymentType: choice,
          level,
          amount,
          currency,
        });
        onPaymentSuccess();
      }
    },
    [
      stripe,
      clientSecret,
      amount,
      email,
      level,
      choice,
      currency,
      elements,
      confirmPayment,
      onPaymentSuccess,
    ]
  );

  const termsUrl = programme.termsUrl
    ? programme.termsUrl
    : formatSlug([
        programme.Category.Title,
        programme.Country.Title,
        programme.Title,
        'terms-conditions',
      ]);

  return (
    <Container className={billingDetailSaved ? '' : 'disabled'}>
      <FormTitle>Payment Method</FormTitle>
      <form
        id="payment-form"
        action=""
        method="post"
        onSubmit={(ev: React.FormEvent<HTMLFormElement>) => handleSubmit(ev)}
      >
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />

        <FormGroup row={true} style={{ marginTop: '56px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptedTerms}
                onChange={onTermsAndConditionsChanged}
                name="terms-and-conditions"
              />
            }
            label={
              <span>
                I have read and agree to the&nbsp;
                <a href={termsUrl} target="_blank" rel="noreferrer">
                  terms and conditions
                </a>
              </span>
            }
          />
        </FormGroup>

        <SubmitButton
          type="submit"
          className={'no-top-margin'}
          form={'payment-form'}
          disabled={
            processing ||
            disabled ||
            succeeded ||
            !billingDetailSaved ||
            !acceptedTerms
          }
          endIcon={processing ? <ChevronRightIcon /> : <></>}
        >
          <span id="button-text">
            {processing ? (
              <Loading size={25} color={LoaderColour.white} />
            ) : (
              'Finished & Pay'
            )}
          </span>
        </SubmitButton>

        {error && (
          <CardError role="alert">
            <WarningIcon fontSize="small" />
            <CardErrorMessage>{error}</CardErrorMessage>
          </CardError>
        )}
      </form>
    </Container>
  );
};

export { StripePayment };
