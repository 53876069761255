import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IProduct, IProgramme } from '@bunac/lib/model/Programme';
import { ICurrency } from '@bunac/components/config/currency';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  LevelButton,
  SelectedButton,
} from '@bunac/components/forms/Form/SubmitButton';
import { totalDisplay } from '@bunac/components/forms/Onboarding/Helper';
import CheckIcon from '@material-ui/icons/Check';

interface ProgrammeLevelItemProps {
  currency: ICurrency;
  programme: IProgramme;
  product: IProduct;
  selected: boolean;
  onSelect: (level: IProduct) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  position: relative;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid #fff;

  &.selected {
    border: 1px solid #0094a5;
  }
`;

const Content = styled.div`
  flex: 1 1 0;
  padding: 18px 18px 70px;
  background: #f3f3f3;
`;

const Title = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    font-size: 21px;
  }
`;

const Description = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 40px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`;

const Price = styled.div`
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  color: #0094a5;
  font-weight: 500;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

const Checkbox = styled.div`
  position: absolute;
  top: 24px;
  right: 22px;
  display: flex;
  background: #0094a5;
  padding: 4px;
  color: #fff;
`;

const ProgrammeLevelItem: FunctionComponent<ProgrammeLevelItemProps> = ({
  currency,
  programme,
  product,
  selected,
  onSelect,
}) => {
  const image = getImage(programme.Key_Visual);

  const onLevelSelected = (e: any) => {
    e.preventDefault();
    onSelect(product);
  };

  return (
    <Container className={selected ? 'selected' : ''}>
      {image && <GatsbyImage image={image} alt={programme.Title} />}
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: product.Title }}></Title>
        <Description dangerouslySetInnerHTML={{ __html: product.Content }}></Description>
        <Price>{totalDisplay(programme, product, currency.code)}</Price>
        {selected && <SelectedButton disabled={true}>Selected</SelectedButton>}
        {!selected && (
          <LevelButton onClick={onLevelSelected}>Select</LevelButton>
        )}
        {selected && (
          <Checkbox>
            <CheckIcon fontSize={'small'} />
          </Checkbox>
        )}
      </Content>
    </Container>
  );
};

export { ProgrammeLevelItem };
