import React, {
  useState,
  useCallback,
  useEffect,
  FunctionComponent,
} from 'react';
import Form from '@bunac/components/forms/Form';
import rightChevronSvg from '@bunac/components/mybunac/Application/assets/right-chevron.svg';
import { DATA_MODELS } from '@bunac/components/forms/Form/Field';
import { FormTitle } from './FormTitle';
import { useCheckout } from '@bunac/hooks/useCheckout';
import Programme from '@bunac/Product';

const FORM_ID = 'billing_details';
const PIPELINE_ID = 3;

const formConfig = [
  {
    label: 'Address Line 1',
    name: 'Address Line 1',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Address Line 2',
    name: 'Address Line 2',
    isRequired: false,
    model: DATA_MODELS.person,
  },
  {
    label: 'Town',
    name: 'Town',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'State/County',
    name: 'State/County',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'ZIP / Postal Code',
    name: 'ZIP / Postal Code',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Country',
    name: 'Country',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Email Address',
    name: 'Email',
    isRequired: true,
    isHidden: true,
    model: DATA_MODELS.person,
  },
  {
    label: "What's your preferred currency?",
    name: 'Preferred Currency',
    isRequired: true,
    isHidden: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Currency',
    name: 'Currency of Programme Invoice Amount',
    isRequired: true,
    isHidden: true,
    model: DATA_MODELS.deal,
  },
];

interface IBillingDetails {
  disabled: boolean;
  currency: string;
  onNext: () => void;
}

export const BillingDetails: FunctionComponent<IBillingDetails> = ({
  disabled,
  currency,
  onNext,
}) => {
  const { email, dealId } = useCheckout();
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    setInitValues({
      email,
      dd76fa498b6b5a796d469d58c89bb0596fe45c00: currency,
      db508dbae2b8a3fada712d1b2a91a571f0109ddb_currency: currency,
    });
  }, [email, currency]);

  const submit = useCallback(() => {
    onNext();
  }, [onNext]);

  return (
    <>
      <FormTitle>Current Address</FormTitle>
      <Form
        formId={FORM_ID}
        dealId={`${dealId}`}
        pipelineId={PIPELINE_ID}
        config={formConfig}
        initValues={initValues}
        icon={rightChevronSvg}
        onSubmit={submit}
        buttonAlignment="left"
        submitButton={true}
        disabled={disabled}
        activities={['Customer: Full Registration', 'Customer: Choose Level']}
        buttonText={'Save & Continue'}
      />
    </>
  );
};
