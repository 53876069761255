import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { SubmitButton } from '@bunac/components/forms/Form/SubmitButton';
import { useAuth0 } from '@auth0/auth0-react';
import createPersistedState from 'use-persisted-state';
import {
  currency as getCurrency,
  DEFAULT_CURRENCY,
  ICurrency,
} from '@bunac/components/config/currency';
import { useCheckout } from '@bunac/hooks/useCheckout';
import { PaymentChoices } from './PaymentChoice';
import { depositDisplay, totalDisplay } from './Helper';
import CheckIcon from '@material-ui/icons/Check';

const Title = styled.h2`
  font-weight: 500;

  @media screen and (min-width: 768px) {
    top: 4px;
    display: inline-block;
    margin: 0 0 45px 0;
  }
`;

const Message = styled.p`
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  color: #0094a5;
  font-weight: 500;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    font-size: 24px;
    margin-bottom: 32px;
  }
`;

const OrderSummary = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  background: #f3f3f3;
  color: #0094a5;
  padding: 24px;
  margin: 0 32px 20px;

  @media screen and (min-width: 768px) {
    font-size: 24px;
    padding: 48px;
    margin: 0 32px 24px;
  }
`;

const Text = styled.div``;

const Checkbox = styled.div`
  display: flex;
  background: #0094a5;
  padding: 4px;
  margin-right: 24px;
  color: #fff;
`;

const RegisterContainer = styled.div`
  margin: -12px 0 0;
`;

const useCurrency = createPersistedState('currency');

export const ThankYou: FunctionComponent<{}> = () => {
  const { programme, level, paymentType } = useCheckout();

  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const [symbol, _] = useCurrency();

  const currency = getCurrency(symbol || DEFAULT_CURRENCY.code);

  const deposit = programme ? depositDisplay(programme, currency.code) : 0;

  const total =
    programme && level ? totalDisplay(programme, level, currency.code) : 0;

  const value = paymentType === PaymentChoices.DEPOSIT ? deposit : total;

  return (
    <>
      <Title>Thank you for purchase</Title>
      <Message>
        You will receive an email confirmation of your booking shortly. Be sure
        to check your junk folder, and contact us if you don’t receive within
        the next 30 minutes.
      </Message>
      <OrderSummary>
        <Checkbox>
          <CheckIcon />
        </Checkbox>
        <Text>
          {paymentType === PaymentChoices.DEPOSIT ? 'Deposit' : 'Full Trip'} -{' '}
          {value}
        </Text>
      </OrderSummary>
      {!isLoading && !isAuthenticated && (
        <RegisterContainer>
          <SubmitButton
            endIcon={<ChevronRightIcon />}
            onClick={loginWithRedirect}
          >
            Register your account
          </SubmitButton>
        </RegisterContainer>
      )}
    </>
  );
};
