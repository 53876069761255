import React, { FunctionComponent } from 'react';
import { useCheckout } from '@bunac/hooks/useCheckout';

import styled from 'styled-components';

const ProgressBar = styled.div`
  display: flex;
  justify-content: stretch;
  padding: 0;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    margin-bottom: 60px;
  }
`;

interface ProgressStepProps {
  readonly current: boolean;
  readonly completed: boolean;
}

const ProgressStep = styled.div<ProgressStepProps>`
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 13px;
  border-top: 3px solid;
  border-color: ${(props) =>
    props.current ? '#0094A5' : props.completed ? '#005C66' : '#CBCBCB'};
  color: ${(props) =>
    props.current ? '#0094A5' : props.completed ? '#005C66' : '#CBCBCB'};

  @media screen and (min-width: 768px) {
    padding-top: 20px;
  }
`;

const ApplicationProgress: FunctionComponent<{}> = () => {
  const { stages, stage } = useCheckout();

  if (stages === undefined || stage === undefined) {
    return <></>;
  }

  return (
    <ProgressBar>
      {stages.map((title, i) => {
        if (i === 3) {
          return <></>;
        }
        return (
          <ProgressStep key={i} current={stage === i} completed={stage > i}>
            {title}
          </ProgressStep>
        );
      })}
    </ProgressBar>
  );
};

export { ApplicationProgress };
