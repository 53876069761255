import React, { useState, FunctionComponent, useCallback } from 'react';
import { IProduct, IProgramme } from '@bunac/lib/model/Programme';
import { ICurrency } from '@bunac/components/config/currency';
import styled from 'styled-components';
import { PaymentChoice, PaymentChoices } from './PaymentChoice';
import { StripePayment } from './StripePayment';
import { depositDisplay, deposit, totalDisplay, total } from './Helper';

interface ICheckoutForm {
  currency: ICurrency;
  programme: IProgramme;
  level: IProduct;
  active: boolean;
  onNext: (choice: PaymentChoices) => void;
}

const Container = styled.div`
  margin: 32px 0;

  @media screen and (min-width: 768px) {
    margin: 0 0 32px;
  }
`;

export const CheckoutForm: FunctionComponent<ICheckoutForm> = ({
  currency,
  programme,
  level,
  active,
  onNext,
}) => {
  const [paymentChoice, setPaymentChoice] = useState<PaymentChoices>(
    PaymentChoices.DEPOSIT
  );

  const onPaymentChoiceChanged = useCallback((choice: PaymentChoices) => {
    setPaymentChoice(choice);
  }, []);

  const depositValue = depositDisplay(programme, currency.code);
  const depositAmount = deposit(programme, currency);

  const totalValue = totalDisplay(programme, level, currency.code);
  const totalAmount = total(programme, level, currency);

  return (
    <Container>
      <PaymentChoice
        deposit={depositValue}
        total={totalValue}
        choice={paymentChoice}
        onChange={(choice: PaymentChoices) => onPaymentChoiceChanged(choice)}
      />
      <StripePayment
        billingDetailSaved={active}
        programme={programme}
        level={level}
        choice={paymentChoice}
        currency={currency}
        amount={
          paymentChoice === PaymentChoices.DEPOSIT ? depositAmount : totalAmount
        }
        onPaymentSuccess={() => onNext(paymentChoice)}
      />
    </Container>
  );
};
