import {
  IProduct,
  ProductType,
  IProgramme,
  IPricingList,
} from '@bunac/lib/model/Programme';
import {
  currency as getCurrency,
  DEFAULT_CURRENCY,
  ICurrency,
} from '@bunac/components/config/currency';

export const deposit = (programme: IProgramme, currency: ICurrency) => {
  const deposits = programme.Products.filter(
    (p: IProduct) => p.Type === ProductType.Deposit
  );

  if (deposits.length === 0) {
    return 0;
  }
  return deposits[0].Cost[currency.code as keyof IPricingList] || 0;
};

const secondPayment = (programme: IProgramme, currency: ICurrency) => {
  const secondPayments = programme.Products.filter(
    (p: IProduct) => p.Type === ProductType.SecondPayment
  );

  if (secondPayments.length === 0) {
    return 0;
  }
  return secondPayments[0].Cost[currency.code as keyof IPricingList] || 0;
};

export const total = (
  programme: IProgramme,
  level: IProduct,
  currency: ICurrency
) => {
  const programmeCost = deposit(programme, currency);
  const secondPaymentCost = secondPayment(programme, currency);
  const levelCost =
    (level.Cost && level.Cost[currency.code as keyof IPricingList]) || 0;

  return programmeCost + levelCost + secondPaymentCost;
};

export const depositDisplay = (
  programme: IProgramme,
  currencySymbol: string
) => {
  const currency = getCurrency(currencySymbol || DEFAULT_CURRENCY.code);
  return `${currency.symbol}${deposit(programme, currency)}`;
};

export const totalDisplay = (
  programme: IProgramme,
  level: IProduct,
  currencySymbol: string
) => {
  const currency = getCurrency(currencySymbol || DEFAULT_CURRENCY.code);
  return `${currency.symbol}${total(programme, level, currency)}`;
};
