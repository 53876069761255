import React, { FunctionComponent } from 'react';

import styled from 'styled-components';
import { useCheckout } from '@bunac/hooks/useCheckout';
import CurrencyList from '../heroheader/currency-list';

const Container = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const Step = styled.div`
  text-transform: uppercase;
  background: #0094a5;
  color: #fff;
  display: inline-block;
  padding: 16px 10px;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
    padding: 10px 24px;
    margin-bottom: 45px;
  }
`;

const Title = styled.h2`
  font-weight: 500;

  @media screen and (min-width: 768px) {
    top: 4px;
    display: inline-block;
    margin: 0 0 45px 20px;
  }
`;

const ApplicationStepTitle: FunctionComponent<{}> = () => {
  const { stages, stage } = useCheckout();

  if (stages === undefined || stage === undefined) {
    return <></>;
  }

  return (
    <Container>
      <Step>
        Step {stage + 1}/{stages.length - 1}
      </Step>
      {stage !== 0 && <CurrencyList />}
      <Title>{stages[stage]}</Title>
    </Container>
  );
};

export { ApplicationStepTitle };
