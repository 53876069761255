import React, { useState, useCallback, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout, { Content } from './components/Layout';
import { CompleteApplication } from './components/forms/Onboarding/CompleteApplication';
import { ProgrammeLevel } from './components/forms/Onboarding/ProgrammeLevel';
import { ApplicationHeader } from './components/application/ApplicationHeader';
import { ApplicationProgress } from './components/application/ApplicationProgress';
import { ApplicationStepTitle } from './components/application/ApplicationStepTitle';
import { PaymentForm } from './components/forms/Onboarding/PaymentForm';
import { ThankYou } from './components/forms/Onboarding/ThankYou';
import { useCheckout } from './hooks/useCheckout';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { siteMetadata } from '@bunac/../gatsby-config';
import { Loading } from './components/common/Loading';

const stripePromise = loadStripe(siteMetadata.Stripe.KEY);

export const query = graphql`
  query BookingQuery($id: Int!) {
    strapiProgrammes(strapiId: { eq: $id }) {
      strapiId
      Duration
      Title
      Pipedrive_ID
      Cost {
        EUR
        GBP
        USD
        AUD
        NZD
      }
      Deposit {
        EUR
        GBP
        USD
        AUD
        NZD
      }
      Category {
        Title
      }
      Country {
        Title
      }
      Eligibility {
        Title
      }
      Key_Visual {
        childImageSharp {
          gatsbyImageData(
            width: 354
            placeholder: BLURRED
          )
        }
      }
      Products {
        id
        Content
        Identifier
        Title
        Type
        Cost {
          EUR
          SKU
          GBP
          USD
          AUD
          NZD
          id
        }
      }
    }
  }
`;

const Book = ({ data, pageContext, location }) => {

  const {
    programme,
    setProgramme,
    products,
    stage,
    setStage,
    level,
    setLevel,
    paymentChoice,
    setPaymentChoice
  } = useCheckout();

  const { termsUrl } = pageContext;

  const content = data.strapiProgrammes;

  useEffect(() => {
    content.termsUrl = termsUrl;
    setProgramme(content);
  }, [content, setProgramme]);

  const onNext = useCallback(() => {
    window.scrollTo(0, 0);
    setStage(stage + 1);
  }, [stage]);

  if (programme === null || products.length === 0) {
    return (
      <Layout title='Application for...'>
        <Elements stripe={stripePromise}>
          <Content style={{ marginLeft: '0', margin: '0 auto' }}>
            <Loading />
          </Content>
        </Elements>
      </Layout>
    );
  }

  return (
    <Layout title={`Application for ${programme.Title}`}>
      <Elements stripe={stripePromise}>
        <Content style={{ marginLeft: '0', margin: '0 auto' }}>
          <ApplicationHeader />
          <ApplicationProgress />
          {stage !== 3 && <ApplicationStepTitle /> }
          {stage === 0 && <CompleteApplication onNext={onNext} />}
          {stage === 1 && <ProgrammeLevel onNext={onNext} />}
          {stage === 2 && <PaymentForm onNext={onNext} />}
          {stage === 3 && <ThankYou programme={programme} level={level} choice={paymentChoice} />}
        </Content>
      </Elements>
    </Layout>
  );
};

export default Book;