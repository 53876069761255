import React, { useState, useEffect, FunctionComponent } from 'react';
import Form from '@bunac/components/forms/Form';
import rightChevronSvg from '@bunac/components/mybunac/Application/assets/right-chevron.svg';
import { useMyBunac } from '@bunac/hooks/useMyBunac';
import { ThemeProvider } from '@material-ui/core';
import { FIELD_TYPES, DATA_MODELS } from '@bunac/components/forms/Form/Field';
import { theme } from '@bunac/lib/themes';
import { useCheckout } from '@bunac/hooks/useCheckout';
import { IProfile } from '@bunac/lib/model/Profile';
import createPersistedState from 'use-persisted-state';
import { Currencies } from '@bunac/components/config/currency';

const useCurrency = createPersistedState('currency');

const FORM_ID = 'complete_application';
const PIPELINE_ID = 3;

const formConfig = [
  {
    label: 'First Names (including any middle names)',
    name: 'First Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Last Name',
    name: 'Last Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Email Address',
    name: 'Email',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Phone-Number',
    name: 'Phone',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Nationality',
    name: 'Nationality (from your passport)',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    type: FIELD_TYPES.lineOfText,
    style: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    label: (
      <p>
        BUNAC will only use these details to contact you about your work abroad
        booking. Please see the{' '}
        <a href="/privacy" target="_blanc">
          <b>BUNAC privacy policy</b>
        </a>{' '}
        for more information. You can manage your marketing communication
        preferences below.
      </p>
    ),
  },
  {
    type: FIELD_TYPES.lineOfText,
    label: <p>I would like to receive marketing materials via:</p>,
  },
  {
    label: 'Email',
    name: 'Communication Consent Email',
    model: DATA_MODELS.person,
  },
  {
    label: 'SMS',
    name: 'Communication Consent SMS',
    model: DATA_MODELS.person,
  },
  {
    label: 'Phone',
    name: 'Communication Consent Phone',
    model: DATA_MODELS.person,
  },
  {
    label:
      'To give you the best online customer experience, we would like to pass your data onto third parties such as Facebook and Instagram. Are you OK for us to pass your data onto third parties?',
    name: 'Communication Consent Social',
    model: DATA_MODELS.person,
  },
  {
    label: 'What program are you interested in?',
    name: 'Programme',
    isRequired: true,
    isHidden: true,
    model: DATA_MODELS.deal,
  },
  {
    label: "What's your preferred currency?",
    name: 'Preferred Currency',
    isRequired: true,
    isHidden: true,
    model: DATA_MODELS.person,
  },
];

interface ICompleteApplication {
  onNext: () => void;
}

export const CompleteApplication: FunctionComponent<ICompleteApplication> = ({
  onNext,
}) => {
  const { profile } = useMyBunac() as { profile: IProfile };
  const [initValues, setInitValues] = useState({});
  const { programme, setDealId, setPersonId, setEmail } = useCheckout();
  const [currency] = useCurrency(Currencies.default);
  const [fields, setFields] = useState<any>([]);

  useEffect(() => {
    if (!profile && programme) {
      return setInitValues({
        b0975083fc62b12746c82176eda3826150cf68b0: programme.Pipedrive_ID,
        dd76fa498b6b5a796d469d58c89bb0596fe45c00: currency,
      });
    }

    if (programme) {
      setInitValues({
        ...profile,
        phone: profile.phone ? profile.phone[0].value : undefined,
        email: profile.email ? profile.email[0].value : undefined,
        b0975083fc62b12746c82176eda3826150cf68b0: programme.Pipedrive_ID,
        dd76fa498b6b5a796d469d58c89bb0596fe45c00: currency,
      });
    }
  }, [profile, programme, currency]);

  useEffect(() => {
    if (!programme || programme.Pipedrive_ID !== 1754) {
      setFields(formConfig);
      return;
    }
    let config = formConfig;
    config.splice(5, 0, {
      label:
        'Intern in Britain placement provider (only if booking Intern in Britain)',
      name: 'Supplier',
      isRequired: false,
      model: DATA_MODELS.deal,
    });
    setFields(config);
  }, [fields, programme]);

  const submit = (rsp: any) => {
    if (setDealId) {
      setDealId(rsp.deal);
    }

    if (setPersonId) {
      setPersonId(rsp.person);
    }

    if (setEmail) {
      setEmail(rsp.email);
    }
    onNext();
  };

  return (
    <ThemeProvider theme={theme}>
      <Form
        formId={FORM_ID}
        pipelineId={PIPELINE_ID}
        config={fields}
        initValues={initValues}
        icon={rightChevronSvg}
        onSubmit={submit}
        buttonAlignment="left"
        submitButton={true}
        activities={[]}
        buttonText={'Submit & Continue'}
      />
    </ThemeProvider>
  );
};
